import React, { ReactNode } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

interface ApolloAppProviderProps {
  children: ReactNode;
}

const graphqlURL =
  process.env.NODE_ENV === 'production'
    ? process.env.PROD_URL
    : process.env.DEV_URL;

const client = new ApolloClient({
  uri: graphqlURL,
  cache: new InMemoryCache(),
});

const ApolloAppProvider: React.FC<ApolloAppProviderProps> = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloAppProvider;
