import React from 'react';

interface IframeComponentProps {
  src: string;
  title: string;
  className?: string;
}

const IframeComponent: React.FC<IframeComponentProps> = ({
  src,
  title,
  className = "border-none mx-auto h-[31rem]"
}) => {
  return (
    <iframe
      src={src}
      title={title}
      className={className}
    />
  );
};

export default IframeComponent;
