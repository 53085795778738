import React from 'react';

interface TextProps {
  text: string;
}

const Text: React.FC<TextProps> = ({ text }) => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center gap-8 p-4 mx-auto max-w-4xl">
      <p className="text-base md:text-lg text-gray-700 text-justify">{text}</p>
    </div>
  );
};

export default Text;
