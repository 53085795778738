import React from 'react';

interface LinkProps {
  LinkText: string;
  LinkUrl: string;
}

const Link: React.FC<LinkProps> = ({
  LinkText,
  LinkUrl,
}) => {
  return (
    <a href={LinkUrl} target="_blank" rel="noopener noreferrer" className="underline mx-1 text-sm" style={{ color: '#212A3E' }}>
      {LinkText}
    </a>
  );
};

export default Link;
