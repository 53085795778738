import ContentFrame from '../ContentFrame';
import Link from '../Link';

const Powell = () => {
  return (
    <>
      <ContentFrame 
        title="Water in the West" 
        videoSrc={process.env.REACT_APP_DASH_URL_POWELL || ''}
        iframeHeight="h-[35rem]"
      />
      <p className="flex justify-center w-full mt-4 text-sm" style={{ color: '#212A3E' }}>
        Source: 
        <Link
          LinkText="Landsat Surface Reflectance"
          LinkUrl="https://developers.google.com/earth-engine/datasets/catalog/landsat"
        />
        powered by GEE.
      </p>
    </>
  );
};

export default Powell;

// TM-ETM-OLI
