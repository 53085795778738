import React from 'react';
import Title from './Title';

interface VisualLinkProps {
  title: string;
  imageUrl: string;
  onClick: () => void;
}

const VisualLink: React.FC<VisualLinkProps> = ({ title, imageUrl, onClick }) => {
  return (
    <div className="block w-72 h-72 rounded overflow-hidden shadow-lg m-4 cursor-pointer" onClick={onClick}>
      <Title text={title} size="text-lg" color="header-blue" marginTop="mt-1" className="text-center mb-0" />
      <img className="w-full h-full object-cover" src={imageUrl} alt={title} />
    </div>
  );
};

export default VisualLink;