import React from 'react';
import { StyledNav } from '../styles/NavStyles';
import { Nav } from 'react-bootstrap';

interface NavbarProps {
  selectedTab: string | undefined;
  onSelect: (selectedKey: string) => void;
}

const Navbar: React.FC<NavbarProps> = ({ selectedTab, onSelect }) => {
  const handleSelect = (eventKey: string, event: React.SyntheticEvent) => {
    onSelect(eventKey);
  };

  return (
    <StyledNav variant="tabs" activeKey={selectedTab} onSelect={handleSelect as any}>
      <Nav.Item>
        <Nav.Link eventKey="Home">Stories</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="About">About</Nav.Link>
      </Nav.Item>
    </StyledNav>
  );
};

export default Navbar;
