import React from 'react';
import Title from './Title';
import Text from './Text';
import Image from './Image';

const About: React.FC = () => {
  return (
    <div className="about-container p-4 md:p-8 lg:p-12">
        <Title text="Inspiration" size="text-xl md:text-2xl lg:text-3xl" className="font-bold text-center mb-8" />
        
        <Text text="Moda is a collection of stories, adventures, and data inspired by curiosity, a deep love for Planet Earth, and Edward Abbey." />
        <Image 
          src="/gentil.png" 
          alt="Description of Image" 
          subtitle='"Be gentil with everything that lives." - Cloudbridge Reserve, Costa Rica'
          className="mx-auto" />
    </div>
  );
};

export default About;
