import React, { useState } from 'react';
import Header from './Header';
import Navbar from './Navbar';
import GHGPlot from './content/GHGPlot';
import GEOPlot from './content/GEOPlot';
import Powell from './content/Powell';
import { HomeContainer } from '../styles/HomeStyles';
import Stories from './Stories';
import Ausungate from './content/Ausungate';
import About from './About';

const Home: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string | undefined>('Home');

  const handleSelect = (selectedKey: string) => {
    setSelectedTab(selectedKey);
  };

  return (
    <HomeContainer>
      <Header />
      <Navbar selectedTab={selectedTab} onSelect={handleSelect} />
      {selectedTab === 'Home' && <Stories onSelect={handleSelect} />}
      {selectedTab === 'Powell' && <Powell />}
      {selectedTab === 'Ausungate' && <Ausungate />}
      {selectedTab === 'geoData' && <GEOPlot />}
      {selectedTab === 'ghgData' && <GHGPlot />}
      {selectedTab === 'About' && <About />}
    </HomeContainer>
  );
};

export default Home;
