import ContentFrame from '../ContentFrame';
import IFrame from '../IFrame';
import Link from '../Link';

const GHGPlot = () => {
  return (
    <>
      <ContentFrame 
        title="GHG Emissions by Companies"
        videoSrc={process.env.REACT_APP_DASH_URL_GHG || ''}
        iframeHeight="h-[27rem]"
      />
      <div className="flex justify-center w-full">
        <IFrame
          src={process.env.REACT_APP_DASH_URL_GHG_COMP || ''}
          title=''
          className="w-[75rem] h-[54rem]"
        />
      </div>
      <p className="flex justify-center w-full mt-4 text-sm" style={{ color: '#212A3E' }}>
        ESG data sourced from 
        <Link
         LinkText="Wikirate"
         LinkUrl="https://wikirate.org"
        />
        under 
        <Link
         LinkText="CC BY 4.0"
         LinkUrl='https://creativecommons.org/licenses/by/4.0/'
        />
        license.
      </p>
    </>
  );
};

export default GHGPlot;
