import React from 'react';
import VisualLink from './VisualLink';

interface StoriesProps {
  onSelect: (key: string) => void;
}

const Stories: React.FC<StoriesProps> = ({ onSelect }) => {
  return (
    <div className="flex flex-wrap justify-center items-center">
      <VisualLink
        title="Water in the West"
        imageUrl="/powell.png"
        onClick={() => onSelect('Powell')}
      />
      <VisualLink
        title="Apu Ausungate"
        imageUrl="ausungate.png"
        onClick={() => onSelect('Ausungate')}
      />
      <VisualLink
        title="GHG Emissions"
        imageUrl="ghg.png"
        onClick={() => onSelect('ghgData')}
      />
      <VisualLink
        title="Air Trajectories"
        imageUrl="air.png"
        onClick={() => onSelect('geoData')}
      />
    </div>
  );
};

export default Stories;
