import styled from 'styled-components';
import { Nav } from 'react-bootstrap';

export const StyledNav = styled(Nav)`
  .nav-link {
    color: #212A3E;

    // &:hover {
    //   color: ;

    &.active {
      color: #212A3E;
    }
  }
`;
