import React from 'react';
import ApolloAppProvider from './ApolloProvider';
import Home from './components/Home';
import 'bootstrap/dist/css/bootstrap.min.css';

const App: React.FC = () => {
  return (
    <ApolloAppProvider>
      <Home />
    </ApolloAppProvider>
  );
};

export default App;
