import styled from 'styled-components';

export const HeaderContainer = styled.div`
  background-color: #212A3E;
  // color: #F1F6F9;
  color: #9BA4B5;
  padding: 1.3rem;
`;

export const Heading = styled.h1`
  font-size: 2.2rem;
  margin: 0;
`;

export const Subtitle = styled.p`
  font-size: 0.76rem;
  margin: 0;
`;

export const Paragraph = styled.p`
  color: #9BA4B5;   
  font-size: 1rem;
  margin: 0;
  margin-right: 1rem
`;

export const Image = styled.img`
  border-radius: 50%;
  height: 3.8rem;
  objectFit: cover
`;
