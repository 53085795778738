import React from 'react';

interface TitleProps {
  text: string;
  size?: string;
  color?: string;
  className?: string;
  marginTop?: string;
}

const Title: React.FC<TitleProps> = ({
  text,
  size = 'text-xl',
  className = '',
  marginTop = 'mt-5',
}) => {
  return (
    <h1 className={`${size} text-custom-blue text-center py-2 ${marginTop} ${className}`}>
      {text}
    </h1>
  );
};

export default Title;
