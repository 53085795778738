import React from 'react';

interface ImageProps {
  src: string;
  alt: string;
  subtitle?: string;
  className?: string;
}

const Image: React.FC<ImageProps> = ({ src, alt, subtitle, className = '' }) => {
  return (
    <div className={`flex flex-col items-center justify-center gap-2 p-4 mx-auto max-w-2xl ${className}`}>
      <img src={src} alt={alt} className="rounded-lg" />
      {subtitle && <p className="text-sm">{subtitle}</p>}
    </div>
  );
};

export default Image;
