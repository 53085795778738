import ContentFrame from '../ContentFrame';
import Link from '../Link';

const GEOPlot = () => {
  return (
    <>
      <ContentFrame 
        title="Air Trajectories" 
        videoSrc={process.env.REACT_APP_DASH_URL_GEO || ''}
        iframeHeight="h-[35rem]"
      />
      <p className="flex justify-center w-full mt-4 text-sm" style={{ color: '#212A3E' }}>
        Source: 
        <Link
          LinkText="CREA"
          LinkUrl="https://www.crea.org/"
        />
      </p>
    </>
  );
};

export default GEOPlot;
