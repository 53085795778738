import React from 'react';
import Title from './Title';
import IFrame from './IFrame';

interface ContentFrameProps {
  title: string;
  videoSrc: string | undefined;
  className?: string;
  iframeHeight?: string;
  iframeWidth?: string;
}

const ContentFrame: React.FC<ContentFrameProps> = ({
  title,
  videoSrc,
  className = '',
  iframeHeight = 'h-[31rem]',
  iframeWidth = 'w-3/4',
}) => {
  return (
    <div className={`flex flex-col items-center p-4 ${className}`}>
        <div className="w-full">
           <Title text={title} marginTop="mt-3"/>
        </div>
        <div className="flex justify-center w-full">
            <IFrame src={videoSrc || ''} title={title} className={`border-none block mx-auto ${iframeWidth} ${iframeHeight}`} />
        </div>
    </div>
  );
};

export default ContentFrame;
