import React from 'react';
import { HeaderContainer, Heading, Subtitle, Paragraph, Image } from '../styles/HeaderStyles';

const Header: React.FC = () => {
  return (
    <HeaderContainer className='d-flex align-items-center justify-content-between'>
      <div>
        <Heading >MoDa</Heading>
        <Subtitle>motherdata.earth</Subtitle>
      </div>
      <div className='d-flex align-items-center justify-content-end'>
        <Paragraph>Data inspired stories about earth and the human impact.</Paragraph>
        <Image src='/earth.png' alt='Earth'></Image>
      </div>
    </HeaderContainer>
  );
};

export default Header;
